import { createContext, useContext, useEffect, useRef, useState } from "react"
import { createHttpLink, ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloProvider } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useSettings } from "contexts/Settings";
import { setContext } from '@apollo/client/link/context';
import { useAuth } from "contexts/User";

export const CustomApolloClientContext = createContext<{
  setIdToken: (token: string) => void
}>({
  setIdToken: (token: string) => { },
})
export function useCustomApolloClient() {
  const { setIdToken } = useContext(CustomApolloClientContext);

  return {
    setIdToken
  }
}
export const CustomApolloClientProvider = ({ children }: { children: any }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const idTokenRef = useRef<string>();
  const { user, logout } = useAuth();
  const { settings } = useSettings();
  useEffect(() => {
    if (user) {
      user.getIdToken().then((token: string) => {
        setIdTokenRef(token);
      }).catch((error: any) => {
        console.error("getIdToken error", error);
      });
    }
  }, [user])
  useEffect(() => {
    try {
      const httpLink = createHttpLink({
        uri: settings.REACT_APP_GRAPHQL_ENDPOINT,
      });
      const authLink = setContext(async (_, { headers }) => {
        console.log("add auth to graphql", { idToken: idTokenRef.current });
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: idTokenRef.current ? `Bearer ${idTokenRef.current}` : "",
          }
        }
      });
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(async ({ message }) => {
            console.error(`[GraphQL error]: Message: ${message}`);
            if (message === 'Authentication error' || message === 'Context creation failed: Authentication error') {
              logout().then(() => window.location.href = "/login");
            }
          });
        }
      });
      console.log("setting CustomApolloClientProvider client");
      setClient(new ApolloClient({
        link: errorLink.concat(authLink.concat(httpLink)),
        cache: new InMemoryCache()
      }))

    } catch (e) {
      console.log("createClient error", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])
  const setIdTokenRef = (token: string) => {
    console.log("setIdTokenRef", token);
    idTokenRef.current = token;
  }
  if (!client) {
    return <div>Loading...</div>
  }
  return (
    <CustomApolloClientContext.Provider value={{ setIdToken: setIdTokenRef }}>
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    </CustomApolloClientContext.Provider >
  )
}